
import slider2 from "./assets/bg-baige.jpg";
import catagory from "./assets/category-4.png";
import banner2 from "./assets/AboutSectionImg-removebg-preview.png";


import category1 from "./assets/category-1.png";
import category2 from "./assets/category-2.png";
import category3 from "./assets/category-3.png";
import category4 from "./assets/category-4.png";

import client1 from "./assets/client-1.png";
import client2 from "./assets/client-2.jpeg";
import client3 from "./assets/client-3.jpeg";
import client4 from "./assets/client-4.jpeg";
import client5 from "./assets/client-5.jpeg";
import client6 from "./assets/client-6.jpeg";
import client7 from "./assets/client-7.png";
import client8 from "./assets/client-8.jpeg";
import client9 from "./assets/client-9.png";
import client10 from "./assets/client-10.jpeg";

import certificate from "./assets/certificate1.jpeg";
import certificate2 from "./assets/certificate2.jpeg";
import certificate3 from "./assets/certificate3.jpeg";
import certificate4 from "./assets/certificate4.png";

export const sliders = [
    {
        id: 3,
        src: slider2,
        img: banner2,
        heading: "ElKoubasy",
        slugon: "100% Natural Juice with zero Sugar",
        subHeading:
            "El Koubeisy company uses global best practices and standards in hygiene and safety measures and production and packaging methods to ensure top-quality products.",
    },
    {
        id: 3,
        src: slider2,
        img: catagory,
        heading: "ElKoubasy",
        slugon: "100% Natural Juice with zero Sugar",
        subHeading:
            "El Koubeisy company uses global best practices and standards in hygiene and safety measures and production and packaging methods to ensure top-quality products.",
    },
];



export 	const categoriesData = [
    { image: category1, title: "Fresh Vegetables" },
    { image: category2, title: "Fresh Fruits" },
    { image: category3, title: "Ice Cream" },
    { image: category4, title: "Juices" },
];


 export const Clinetsliders = [
    {
        id: 3,
        src: client1,
    },
    {
        id: 2,
        src: client2,
    },
    {
        id: 3,
        src: client3,
    },
    ,
    {
        id: 4,
        src: client4,
    },
    ,
    {
        id: 5,
        src: client5,
    },
    ,
    {
        id: 6,
        src: client6,
    },
    ,
    {
        id: 7,
        src: client7,
    },
    ,
    {
        id: 8,
        src: client8,
    },
    ,
    {
        id: 9,
        src: client9,
    },
    ,
    {
        id: 10,
        src: client10,
    },
];

export 	const certificates = [
    {
        id: 1,
        src: certificate,
        alt: "certificate1",
    },
    {
        id: 2,
        src: certificate2,
        alt: "certificate2",
    },
    {
        id: 3,
        src: certificate3,
        alt: "certificate3",
    },
    {
        id: 4,
        src: certificate4,
        alt: "certificate4",
    },
];