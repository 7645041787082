import React from "react";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const {t} =useTranslation()
	return (
		<footer className="footer pt-5">
			<div class="container  pb-5">
				<div class="row row-cols-1 row-cols-sm-1  row-cols-md-2 row-cols-lg-3">
					<div class="col-lg-4 mt-5 items-footer">
						<a href="/">
							<img className="logoFooter" src={logo} />
						</a>
					</div>
					<div class="col-lg-3 mt-5 items-footer">
						<div className="mt-2 me-5 me-lg-0 link-footer ">
							<a href="#home"> {t("Home")}</a>
						</div>
						<div className="mt-2 me-5 me-lg-0 link-footer">
							<a href="#about"> {t("About Us")}</a>
						</div>
						<div className=" mt-2 me-5 me-lg-0 link-footer">
							<a href="#products"> {t("Catagories")}</a>
						</div>
						<div className=" mt-2 me-5 me-lg-0 link-footer">
							<a href="#certificates">
								{t("Certificates")}
							</a>
						</div>
						<div className=" mt-2 me-5 me-lg-0 link-footer">
							<a href="tel:+201112222490" > {t("Contact Us")}</a>
						</div>
					</div>
					<div class="col-lg-5 mt-5 items-footer">
						<div className="mt-3 link-footer">
							<ul className="list-unstyled">
								<li className="block  lg:flex mb-lg-3 ">
									<a
										className="  pb-0 lg:pb-2 text-base"
										target="_blank"
										href="https://www.google.com/maps/dir//Nasr+City+Cairo+Governorate/@30.051085,31.3655989,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14583c1380cba7ef:0xd541260e9e06978d!2m2!1d31.3655989!2d30.051085?entry=ttu"
									>
										{" "}
										<i className="fa-solid fa-location-dot  me-4 ms-4"></i>
										{t("Third Industrial Zone - 6th of October City - Giza - Egypt")}
									</a>
								</li>
								<li className="block lg:flex  mb-3">
									<a
										className="  pb-0 lg:pb-2 text-base"
										href="mailto:info@elkoubasy.com"
									>
										{" "}
										<i className="fa-solid fa-envelope  me-4 ms-4"></i>
										info@elkoubasy.com
									</a>
								</li>
								<li className="block lg:flex ">
									<a
										className=" font-semibold block "
										href="tel:+201112222490"
									>
										{" "}
										<i className="fa-solid fa-mobile-screen-button  me-4 ms-4 "></i>
										01112222490
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
