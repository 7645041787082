import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import i18n from "../Language/i18n";
import { useTranslation } from "react-i18next";
import usImg from "../assets/us.jpg";
import arImg from "../assets/eg.png";

const Header = () => {
	const [scrolled, setScrolled] = useState(false);
	const [activeNavItem, setActiveNavItem] = useState(null);

	const { i18n, t } = useTranslation();
	const LANGUAGES = [
		{ label: "English", code: "en", src: usImg },
		{ label: "Arabic", code: "ar", src: arImg },
	];

	const onChangeLang = (value) => {
		const lang_code = value;
		console.log(lang_code);
		i18n.changeLanguage(lang_code);
	};
	const handleNavClick = (event, index) => {
		setActiveNavItem(index);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<section
			className={`header-container navbar-custom ${
				scrolled ? "navbar-scrolled " : ""
			}`}
		>
			<nav className="navbar navbar-expand-lg pe-md-5   pe-sm-4  ">
				<div className="container">
					<a className="" href="/">
						<img className="logoImg" src={logo} />
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasNavbar"
						aria-controls="offcanvasNavbar"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					{/* canvas Menu */}
					<div
						style={{ zIndex: "10000" }}
						className="zIndexMenu-canvas"
						class="offcanvas offcanvas-start"
						tabIndex="-1"
						id="offcanvasNavbar"
						aria-labelledby="offcanvasNavbarLabel"
					>
						<div className="offcanvas-header">
							<img className="responsiveImg w-75 h-auto" src={logo} />
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="offcanvas"
								aria-label="Close"
							></button>
						</div>
						<div className="offcanvas-body  d-flex justify-content-center  ">
							<ul className="navbar-nav  align-items-center ">
								<li className=" mt-md-3 mt-lg-0 nav-item ">
									<a
										className={`activeColor ${
											activeNavItem === 0 ? "activeColor" : ""
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 0)}
										aria-current="page"
										href="/"
									>
										{t("Home")}
									</a>
								</li>

								<li className=" mt-md-3 mt-lg-0 nav-item ">
									<a
										className={`${
											activeNavItem === 1 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										href="#about"
										onClick={(e) => handleNavClick(e, 1)}
									>
										{t("About Us")}
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3  mt-md-3 mt-lg-0 ">
									<a
										className={`${
											activeNavItem === 2 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 2)}
										href="#products"
										id="navbarDropdown"
									>
										{t("Catagories")}
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3 mt-md-3 mt-lg-0 ">
									<a
										href="#Clients"
										className={`${
											activeNavItem === 3 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 3)}
									>
										{t("Clients")}
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3 mt-md-3 mt-lg-0 ">
									<a
										href="#certificates"
										className={`${
											activeNavItem === 4 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 4)}
									>
										{t("Certificates")}
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3 mt-md-3 mt-lg-0 ">
									<a
										href="tel:+201112222490"
										className={`${
											activeNavItem === 5 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 5)}
									>
										{t("Contact Us")}
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3 mt-md-3 mt-lg-0 ms-3 ">
								
										<ul class="navbar-nav">
											<li class="nav-item dropdown">
												<button
													class={`btn dropdown-toggle flag-btn`}
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													{i18n.language === "ar" ? (
														<img className="flagImg" src={arImg} />
													) : (
														<img className="flagImg" src={usImg} />
													)}
												</button>
												<ul defaultValue={i18n.language} class="dropdown-menu">
													{LANGUAGES.map(({ code, label, src }) => (
														<li
															onClick={() => onChangeLang(code)}
															key={code}
															className="dropdown-item"
															value={code}
														>
															<img className="flagImg" src={src} />
														</li>
													))}
												</ul>
											</li>
										</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</section>
	);
};

export default Header;
