import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Clinetsliders } from "../data";

const ClientsLogo = () => {
	const { t } = useTranslation();

	const breakpoints = {
		992: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 3,
		},
		576: {
			slidesPerView: 2,
		},
		320: {
			slidesPerView: 1,
		},
	};
	const { i18n } = useTranslation();

	return (
		<section id="Clients" className="container py-5 text-center">
			<h4 className=" section-title">{t("Clients")}</h4>

			<Swiper
				dir={i18n.language === "ar" ? "rtl" : "ltr"}
				key={i18n.language}
				className="slider-container"
				slidesPerView={i18n.language === "ar" ? 4 : 4}
				breakpoints={breakpoints}
				loop={true}
				effect="fade"
				autoplay={{ delay: 1000 }}
				pagination={{ clickable: true }}
				navigation={{
					nextEl: "swiper-button-prev",
					prevEl: "swiper-button-next",
				}}
				modules={[Navigation, Autoplay]}
			>
				{Clinetsliders.map((item) => (
					<SwiperSlide>
						<div>
							<img className="client-img" src={item.src} />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default ClientsLogo;
