import "./App.css";
import Slider from "./Components/Slider";
import Catagory from "./Components/Catagory";
import Header from "./Components/Header";
import Certificates from "./Components/Certificates";
import About from "./Components/About";
import ClientsLogo from "./Components/ClientsLogo";
import Footer from "./Components/Footer";
import CallAction from "./Components/CallAction";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function App() {
	const { i18n } = useTranslation();

	useEffect(() => {
		document.body.setAttribute("dir", i18n.language === "ar" ? "rtl" : "ltr");
	}, [i18n.language]);

	return (
		<div>
			<Header />
			<Slider />
			<div className="container">
				<About />
				<Catagory />
			</div>

			<Certificates />
			<div className="container-clients">
				<ClientsLogo />
			</div>
			<Footer />
			<CallAction />
		</div>
	);
}

export default App;
