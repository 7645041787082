import React, { useState } from 'react'

const CallAction = () => {
    const [isToggled, setIsToggled] = useState(false);

	return (
		<div class={`social-links-fixed ${isToggled?'open':''}`} >
			
			<ul class="clearfix">
				<li class="facebook">
					<a
						href="mailto:info@elkoubasy.com"
						class="btn-facebook-pulse"
					>
						<span class="fas fa-envelope"></span>
					</a>
				</li>

			

				<li class="whatsapp">
					<a href="https://wa.me/+201112222490">
						<span class="fab fa-whatsapp"></span>
					</a>
				</li>

				<li class="twitter">
					<a href="tel:+201112222490">
						<span class="fas fa-phone"></span>
					</a>
				</li>
				<li class="support">
					<a onClick={() => setIsToggled(!isToggled)}>
						<span class="fas fa-comments"></span>
					</a>
				</li>
			</ul>
		</div>
	);
}

export default CallAction