import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from '../Language/en.json'
import arTranslation from '../Language/ar.json'

export const getCurrentLang = () => {
    return i18n.language;
  };

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enTranslation,
		},
		ar: {
			translation: arTranslation,
		},
	},

	lng: getCurrentLang(),
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
