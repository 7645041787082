import React from "react";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { sliders } from "../data";
import CustomSlider from "./CustomSlider";

const Slider = () => {
	const { i18n, t } = useTranslation();
	return (
		<section id="home">
			<CustomSlider
				slidesPerView={i18n.language === "ar" ? 1 : 1}
				effect="fade"
			>
				{sliders.map((item) => (
					<SwiperSlide>
						<div className="background-slider">
							<div className="d-flex  justify-content-between">
								<div className=" col-sm-0 col-md-6 col-lg-6 ">
									<div className="position-absolute  content-Slider ">
										<p className="text-slugon">{t(item.slugon)}</p>
										<h1 className="fw-bold title-content">{t(item.heading)}</h1>
										<p className="col-5 py-md-0 py-lg-3 lh-md-0  text-slider">
											{t(item.subHeading)}
										</p>
										<div className="mt-md-0 mt-lg-4">
											<a
												href="tel:+201112222490"
												className="contact-slider rounded"
											>
												{t("Contact Us")}
											</a>
										</div>
									</div>
								</div>

								<div className=" col-sm-12 col-md-6 col-lg-6  mt-5 imgSlider-container">
									<img className="img-slider" src={item.img} />
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</CustomSlider>
		</section>
	);
};

export default Slider;
