import React from "react";
import { Navigation, Autoplay } from "swiper/modules";
import i18n from "../Language/i18n";
import { Swiper } from "swiper/react";

const CustomSlider = ({
	children,
	slidesPerView,
	loop=true,
	effect,
    breakpoints,
    spaceBetween,
    navigation=true
    
}) => {
	return (
		<Swiper
			dir={i18n.language === "ar" ? "rtl" : "ltr"}
			key={i18n.language}
			modules={[Navigation, Autoplay]}
			slidesPerView={i18n.language === "ar" ? slidesPerView : slidesPerView}
			loop={loop}
			effect={effect}
			autoplay={{ delay: 3000 }}
            breakpoints={breakpoints}
			pagination={{ clickable: true }}
            spaceBetween={spaceBetween}
			navigation=  { navigation && {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
		>
			<>
				<div className="swiper-button-prev "></div>
				<div className="swiper-button-next "></div>
			</>
			{children}
		</Swiper>
	);
};

export default CustomSlider;
