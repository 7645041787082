import React from "react";
import { useTranslation } from "react-i18next";
import { certificates } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import i18n from "../Language/i18n";
import { Autoplay, Navigation } from "swiper/modules";

const Certificates = () => {
	const { t } = useTranslation();
	const breakpoints = {
		1200: {
			slidesPerView: 3,
			spaceBetween: 35,
		},
		992: {
			slidesPerView:3,
			spaceBetween: 30,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		576: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		320: {
			slidesPerView: 1,
		},
	};
	return (
		<section id="certificates" className="mx-md-3 mx-lg-5  py-5">
			<div className="px-10 lg:px-32 py-20 ">
				<div>
					<h4 className="section-title ">{t("Our Certificates")}</h4>
					<p className=" w-75 text-center m-auto mb-5 lh-lg">
						{t(
"Al Qubaisi Company seeks to become the leading company in the field of manufacturing and distributing frozen juices, vegetables and fruits in the world. The company has obtained many international certificates, including the ISO 9001 certificate, the General Authority for the Food and Drug Center certificate, and many other certificates that confirm full commitment to the highest standards. Quality standards and providing high-quality products that meet the needs of consumers and ensure their safety."						)}
					</p>
				</div>

				<div className="">
					<Swiper
						dir={i18n.language === "ar" ? "rtl" : "ltr"}
						key={i18n.language}
						slidesPerView={3}
						loop={true}
						effect={"fade"}
						autoplay={{ delay: 1000 }}
						breakpoints={breakpoints}
						pagination={{ clickable: true }}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						modules={[Navigation, Autoplay]}

					>
						{certificates.map((item) => (
							<SwiperSlide className="shadow">
								<div className="certificateImage">
									<img className="logo-img" src={item.src} />
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
};

export default Certificates;
