import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import { useTranslation } from "react-i18next";
import { categoriesData } from "../data";
const Catagory = () => {
	const breakpoints = {
		1200: {
			slidesPerView: 3,
			spaceBetween: 0,
		},
		992: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		576: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		320: {
			slidesPerView: 1,
		},
	};

	const { i18n, t } = useTranslation();

	return (
		<section id="products" className="catagoryConatiner py-5">
			<p className="section-title">{t("Catagories")}</p>
			<Swiper
				dir={i18n.language === "ar" ? "rtl" : "ltr"}
				key={i18n.language}
				slidesPerView={3}
				loop={true}
				breakpoints={breakpoints}
				effect="fade"
				modules={[Navigation, Autoplay]}
				autoplay={{ delay: 1000 }}
				pagination={{ clickable: true }}
			>
				{categoriesData.map((item) => (
					<SwiperSlide className="d-flex md:gap-4 lg:gap-0 pt-5">
						<div className="catagory-containerSlider ">
							<div>
								<img className="catagory-img " src={item.image} />
							</div>
							<p className="catagory-title">{t(item.title)}</p>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default Catagory;
