import React from "react";
import aboutImg from "../assets/AboutSectionImg.png";
import whatsapp from "../assets/whatsapp.png";
import { useTranslation } from "react-i18next";
const About = () => {
	const { i18n, t } = useTranslation();
	return (
		<section id="about" className="text-center pb-3 pt-5">
			<p className="section-title mb-5">{t("About Us")}</p>
			<div class="">
				<div class="row justify-content-between ">
					<div class="col col-lg-6">
						<img className="aboutImg" src={aboutImg} />
					</div>
					<div class="col-lg-6  lh-lg fs-5 mt-3 about-paragraph">
						<h3 className=" text-uppercase heading-Catagory">
							{t("we are serve 100%")}
						</h3>

						{i18n.language === "ar" ? (
							<div className="d-flex">
								<p className=" text-uppercase heading-catagory ms-2  mb-4">
									{t("product")}
								</p>
								<p className="product-catagory heading-Catagory">
									{t("Natural")}
								</p>
							</div>
						) : (
							<h3 className=" text-uppercase heading-Catagory mb-4">
								{t("Natural")}
								<span className="product-catagory">{t("product")}</span>
							</h3>
						)}

						<p className="about-text">
							{t(
								"Sayed Shaikon is the Chairman of El Koubeisy company, one of thelargest manufacturing companies of freshly squeezed juice in Egypt.",
							)}
						</p>
						<p className="about-text">
							{t(
								"Established in 1999, the company has become a pioneer provider of top-quality fresh juices, frozen vegetables and fruits, and ice cream.",
							)}
						</p>

						<p className="about-text">
							{t(
								"El Koubeisy company uses global best practices and standards in hygiene and safety measures and production and packaging methods to ensure top-quality products .  ",
							)}
						</p>

						<div className="d-flex  align-items-center mt-3">
							<p className="aboutContact">{t("Contact Us :")}</p>
							<a href="https://wa.me/+201112222490" target="_blank">
								<img className="whatsappImg" src={whatsapp} />
							</a>
						</div>
					</div>
				</div>
			</div>

		
		</section>
	);
};

export default About;
